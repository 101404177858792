@import "../../theme.styles.scss";
.calendar-container {
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;

  
  .rbc-calendar {
    > :nth-child(2){
      flex: unset !important;
      height: 450px;
    }
  }
}