@import "../../../../theme.styles.scss";
.status__btn {
  position: relative;
  display: flex;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
  input {
    margin-bottom: 0;
    &::placeholder {
    }
  }
  .colors__color {
    margin-right: 0;
  }
}

.status__arrow {
  position: absolute;
  right: -30px;
  top: 6px;
  svg {
    color: #d8d8da;
    opacity: 0;
  }
}

.status__controls {
  position: absolute;
  top: -10px;
  left: 170px;
  .retroBox {
    padding: 10px !important;
  }
  .boxLayer {
  }
}
.status__setbtn {
  margin-top: 10px;
}

.status__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.status__input {
  width: 100%;
}
.status__delete {
  margin-left: 5px;
  opacity: 1;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    opacity: 1;
    transform: scale(1.15);
    svg {
      color: $danger;
    }
  }
  svg {
    margin-top: 8px;
  }
}
