@import "../../theme.styles.scss";

.Register {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  font-family: "Poppins";
}

.register_error {
  margin-top: 10px;

  p {
    color: $danger;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.register {
  align-items: center;
  display: flex;
  row-gap: 20px;
  flex-direction: column;
}

.register form {
  flex: 2;
  align-items: center;
  display: flex;
  row-gap: 20px;
  flex-direction: column;
}

.header2 h1 {
  color: black
  //font-weight: bold;
}

.register input {
  //background: linear-gradient(
  //                to right bottom,
  //                rgba(255, 255, 255, 0.7),
  //                rgba(255, 255, 255, 0.3)
  //);
  border: none;
  width: 400px;
  height: 30px;
  padding: 1.5rem;
  //border-radius: 2rem;
  //box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  outline: none;
}

.register button {
  background: linear-gradient(
                  to right top,
                  rgba(255, 255, 255, 0.7),
                  rgba(255, 255, 255, 0.3)
  );
  background-color: gray;
  border: none;
  width: 400px;
  height: 40px;
  //border-radius: 2rem;
  //box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.register h2 {
  color: black;
}

.register h4 {
  color: white;
  font-weight: bold;
}


@media (max-width: 500px) {
  
  .register {
    width: calc(100% - 50px);

    form, button, input {
      width: 100% !important;
    }
  }




}