.members {
  position: relative;
}
.members__show {
  border-radius: 10px;
  margin-bottom: 10px;
  transition: 0.2s ease;
  &:last-child {
    margin-bottom: 0;
  }
}

.members__button{
  display: flex;
  justify-content: flex-end;
  
  button{
    background: white;
    color: #426696;
    border: none;
    width: 100px;
    height: 40px;
    border-radius: 1rem;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    font-family: "Poppins";
    color: #426696;
    font-weight: bold;
  }
}
