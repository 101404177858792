@import "../../theme.styles.scss";
.message {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0 20px;
  margin-bottom: 10px;
  .avatar {
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }
}
.message__user {
  display: flex;
  .message__user-name {
    margin-right: 20px;
  }
}
.message__user-time {
  p {
    color: rgb(167, 166, 166);
  }
}
