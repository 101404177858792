.Login {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  font-family: "Poppins";
}

.login__error {
  margin-top: 10px;

  p {
    color: red;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.status1 {
  align-items: center;
  display: flex;
  row-gap: 20px;
  flex-direction: column;
}

.header1 {
  position: relative;
  right: 120px;
}

.header1 h1 {
  color: #426696;
  font-weight: bold;
}

.status1 input {
  background: linear-gradient(
                  to right bottom,
                  rgba(255, 255, 255, 0.7),
                  rgba(255, 255, 255, 0.3)
  );
  background-color: white;
  border: none;
  width: 400px;
  height: 30px;
  padding: 1.5rem;
  //border-radius: 2rem;
  //box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  outline: none;
}

.pass {
  position: relative;
  //bottom: 20px;
  left: 140px;
}

.pass h4 {
  color: white;
  font-weight: bold;
}

.status1 button {
  background: linear-gradient(
                  to right top,
                  rgba(255, 255, 255, 0.7),
                  rgba(255, 255, 255, 0.3)
  );
  background-color: gray;
  border: none;
  width: 400px;
  height: 40px;
  //border-radius: 2rem;
  //box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.status1 h2 {
  color: black;
}

.status1 h4 {
  color: black;
  font-weight: bold;
}

.create {
  position: relative;
  right: 110px;
}


@media (max-width: 500px) {
  
  .status1 {
    width: calc(100% - 50px);
  }
  .status1 button {
    width: 100%;
  }
  .status1 input {
    width: 100%;
  }



}
