
.chatinput{
    width: 100%;
    height: 100%;
    border: 1px solid #E0E0E0;
    border-radius: 2em;
    outline:none !important;
    padding-left: 20px;

}

