//.enter{
//  width: 100%;
//  height: 100vh;
//  overflow-y: scroll;
//   background: linear-gradient(
//    to right bottom,
//    rgba(255, 255, 255, 0.4),
//    rgba(255, 255, 255, 0.1)
//  );
//  z-index: 1;
//  backdrop-filter: blur(1rem);
//  display: flex;
//}

.enterJob {
  width: 100%;
  height: 100vh;
}
