@import "../../../../theme.styles.scss";

.task__logic {
  position: relative;

  &:hover {
    .task__drag {
      opacity: 1;
    }

    .subtask__label-gray {
      opacity: 1 !important;
    }
  }
}

.task {
  position: flex;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  padding: 8px;
  margin-bottom: 8px;
  border: solid;
  border-width: 0.5px;
  border-color: rgba(255, 255, 255, 0.7);
  // background: linear-gradient(
  //   to right bottom,
  //   rgba(255, 255, 255, 0.7),
  //   rgba(255, 255, 255, 0.3)
  // );
  transition: 0.2s ease all;
  position: relative;
  .loadModule {
    height: auto;
  }

  .bl__content {
    width: 70%;
    min-width: 500px;
    max-width: 1000px;
  }

  &:hover {
    .task__expand {
      opacity: 0.2;
    }

    .task_delete_button {
      display: unset;
      color: rgba(175, 0, 0, 0.8);
    }

    .status {
      filter: grayscale(0%);
      opacity: 1;
    }

    .daysLeft,
    .startdate,
    .deadline,
    .assign {
      opacity: 1;
    }

    .checkBox {
      opacity: 1 !important;
    }
  }
}

.task__drag {
  position: absolute;
  top: 5px;
  left: -12px;
  opacity: 0;

  svg {
    color: lightgray;
  }
}

.task__taskName {
  font-weight: 500;
  margin: -3px 0px 0px 5px;
  width: calc(100% - 25px);
  h3 {
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.task__expand {
  position: absolute;
  top: 5px;
  right: 8px;
  opacity: 0;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.4;
    transform: scale(1.15);
  }

  &:active {
    transform: slace(0.95);
  }
}

.task__comments {
  position: absolute;
  top: 6px;
  right: 30px;
  opacity: 1;
  transition: 0.2s ease;
  cursor: pointer;

  svg {
    color: #ccc;
  }

  p {
    position: absolute;
    top: 3px;
    display: flex;
    width: 15px;
    justify-content: center;
    opacity: 1;
    color: #666;
    font-size: 10px;
    font-weight: 800;
  }

  &:hover {
    transform: scale(1.15);
  }

  &:active {
    transform: slace(0.95);
  }
}

/* .task__modules {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-auto-flow: dense;
  justify-content: center;
  margin-top: 20px;
  opacity: 0.3;
  transition: 0.2s ease;
  filter: grayscale(100%);
} */
.task__modules {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  margin-top: 10px;
  /*   font-weight: 500; */
  p {
    font-size: 12px;
  }

  .loadModule {
  }

  // MODUELS
  .deadline,
  .startdate,
  .assign,
  .status {
    opacity: 1;
    transition: 0.2s ease;
  }

  .status {
  }
}

.task__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .checkBox {
    margin-right: 5px;
  }
}

.task__done {
  .task__taskName {
    opacity: 0.2;

    p {
      text-decoration: line-through;
    }
  }

  .task__modules {
    opacity: 0.4;
    filter: grayscale(100%);
    pointer-events: none;
  }
}

.task__expandPlace {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.task_delete_button {
  position: absolute;
  bottom: 17px;
  right: 2px;
  color: rgba(175, 0, 0, 0.8);

  
}

@media (max-width: 500px) {
  .task_delete_button {
    display: unset;
  }
}
