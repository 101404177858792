@import "../../theme.styles.scss";

.board {
  display: flex;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  height: 75vh;
  padding: 10px 15px;
}

::-webkit-scrollbar-track {
  background: $body;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.board__newStatus {
}

.board__dragDelete {
  border-top: 1px solid $bodyGray;
  position: absolute;
  transition: 0.3s ease;
  padding: 10px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $bodyGray;
  opacity: 0.5;

  svg {
    color: rgba(0, 0, 0, 0.4);
    width: 25px !important;
    height: 25px !important;
  }

  &:hover {
    height: 150px;
  }
}

.board__dragDelete-droppable {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* .board__dragDelete {
  position: relative;
  transition: 0.3s ease;
  padding: 10px;
  position: fixed;
  bottom: 10px;
  min-width: 45px;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(226, 68, 92);
  left: 320px;
  border-radius: 10px;
  opacity: 1;
  svg {
    color: white;
    position: absolute;
    bottom: 9px;
    left: 9px;
    width: 25px !important;
    height: 25px !important;
  }
} */
.board__placeholder {
  padding: 10px;
}

// vertical

.board__vertical {
  flex-direction: column;
}
