.jobInfo {
  li {
    svg {
      opacity: 0.5 !important;
    }
  }

  .miniMenu {
    ul {
      grid-template-columns: repeat(2, 40px);
    }
  }
}

.si__title {
  display: flex;
  flex-direction: column;
  max-width: 300px;

  input {
    background: transparent;
    border: 1px dotted rgba(0, 0, 0, 0);
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: -10px;
    outline: none;
    width: auto;

    &::placeholder {
      color: #333;
    }

    &:hover {
      border: 1px dotted gray;
    }

    &:focus {
      border: 1px dotted gray;

      &::placeholder {
        color: gray;
      }
    }
  }

  .si__name {
    input {
      font-size: 1.5em;
      font-weight: 800;
    }
  }
}

.si__btns {
  margin-top: 20%;
  display: flex;
  justify-content: flex-end;
  column-gap: 5px;

  button {
    height: 30px;
  }

  .deleteBtn {
    button {
      background: linear-gradient(to right top, #9f71eb, #3cfabe);
      border-radius: 2rem;
      color: white;
      border: none;
      height: 30px;
      width: 70px;
    }
  }
}

@media (max-width: 500px) {
  .jobInfo {
    .filter__byPerson {
      position: absolute;
      top: 12px;
      right: 20px;
    }
  }
}
