@import "../../../theme.styles.scss";

.sn__name {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;

  p {
    margin-bottom: 5px;
  }

  input {
    width: fit-content;
  }
}

.sn__next {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.sn__icon {
  width: 100%;
  display: flex;
  justify-content: center;

  // svg {
  //   color: $primary;
  // }
}

@media (max-width: 500px) {
  .sn__name {
    input {
      width: 100% !important;
    }
  }
}
