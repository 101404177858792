.createJob {
  .retroBox {
    background-color: white;
    position: relative;
  }

  h2 {
    margin-bottom: 10px;
    text-align: center;
  }
}

.cs__createPop {
  width: 400px;
}

.cs__create-btn {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 500px) {
  .cs__createPop {
    width: 94% !important;
  }
}
