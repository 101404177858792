.taskDescription {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  textarea {
    border: 1px solid white;
    &:hover {
      border: 1px solid #e4e4e4;
    }
  }
}
.taskDescription-text {
  margin-right: 10px;
  margin-top: 14px;
}
.taskDescription-box {
  width: 100%;
}
.taskDescription-button {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 35px;
  margin-top: -36px;
}
