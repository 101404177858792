.modules {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.modules__window {
  position: fixed;
  right: 70px;
  top: 170px;
  width: 300px;
}
.modules__win {
  .retroBox {
    padding: 10px !important;
  }
  .bl__content {
    right: 70px;
    top: 170px;
    width: 300px;
    position: absolute;
  }
  .bl__layer {
    background: transparent;
    backdrop-filter: none;
  }
}

.modules__button{
  button{
    background: linear-gradient(to right top, #9F71EB, #3CFABE);
    border-radius: 2rem;
    color: white;
    border: none;
    height: 40px;
    width: 120px;
  }
}
