@charset "UTF-8";
.svd_container .svd-svg-icon {
  display: inline-block;
  vertical-align: middle;
  fill: #3d4d5d;
  fill: var(--primary-icon-color, #3d4d5d);
}
.svd_container .svd-primary-icon .svd-svg-icon {
  fill: #1ab394;
  fill: var(--primary-color, #1ab394);
}
.svd_container .svd-danger-icon .svd-svg-icon {
  fill: #ed5565;
  fill: var(--error-color, #ed5565);
}
.svd_container .svd-secondary-icon .svd-svg-icon {
  fill: #1ab394;
  fill: var(--secondary-color, #1ab394);
}
.svd_container .icon-gearactive .svd-svg-icon, .svd_container .icon-dotsactive .svd-svg-icon {
  fill: #1ab394;
  fill: var(--primary-color, #1ab394);
}
.svd_container .icon-gear .svd-svg-icon, .svd_container .icon-dots .svd-svg-icon {
  fill: #d0d0d0;
  fill: var(--light-control-color, #d0d0d0);
}
.svd_container .icon-white .svd-svg-icon {
  fill: #ffffff;
  fill: var(--primary-bg-color, #ffffff);
}
.svd_container .icon-status .svd-svg-icon {
  fill: #9d9fa1;
  fill: var(--icon-status-color, #9d9fa1);
  margin-bottom: 0.3em;
}
.svd_container .icon-actiondragelement {
  cursor: move;
  position: absolute;
  left: 0;
}
.svd_container .glyphicon {
  font-weight: bold;
  display: inline-block;
  font-family: inherit;
  cursor: pointer;
  line-height: 1em;
  top: 0;
}
.svd_container .glyphicon.glyphicon-plus::before {
  font-weight: normal;
  font-size: 20px;
  content: "+";
}
.svd_container .glyphicon.glyphicon-option-horizontal::before {
  font-size: 20px;
  content: "⋯";
}
.svd_container .glyphicon.glyphicon-remove::before {
  content: "✖";
}
.svd_container .glyphicon.glyphicon-edit::before {
  content: "";
}
.svd_container .glyphicon.glyphicon-list-alt::before {
  content: "⇐";
}
.svd_container .glyphicon.glyphicon-exclamation-sign::before {
  content: "⚠";
}

.svd_container .svd-tabs {
  padding: 19px 15px 0 30px;
}
.svd_container .svd-tab.active > .svd-tab-text {
  color: #1ab394;
  color: var(--primary-color, #1ab394);
  background-color: #f4f4f4;
  background-color: var(--secondary-bg-color, #f4f4f4);
  border: 1px solid #e7eaec;
  border-color: var(--primary-border-color, #e7eaec);
  border-bottom-color: #f4f4f4;
  border-bottom-color: var(--secondary-bg-color, #f4f4f4);
}
.svd_container .svd-tab-text {
  border: 1px solid transparent;
  border-radius: 0;
  padding: 0.5em 1em;
  text-align: center;
  font-weight: bold;
  color: #676a6c;
  color: var(--primary-text-color, #676a6c);
  background-color: transparent;
}
.svd_container .svd-tab-text:hover, .svd_container .svd-tab-text:focus {
  background-color: #f4f4f4;
  background-color: var(--secondary-bg-color, #f4f4f4);
  border-bottom: 1px solid #f4f4f4;
  border-bottom-color: var(--secondary-bg-color, #f4f4f4);
}

.svd-survey-placeholder-root .svd-survey-placeholder {
  position: relative;
  width: 100%;
  height: 500px;
}
.svd-survey-placeholder-root .svd-survey-placeholder .svd-survey-gallery {
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #f4f4f4;
  background-color: var(--secondary-bg-color, #f4f4f4);
  margin: 6px;
}
.svd-survey-placeholder-root .svd-survey-placeholder .svd-survey-gallery a {
  color: #676a6c;
  color: var(--primary-text-color, #676a6c);
  margin-top: 50px;
  font-size: 14px;
  text-decoration: underline;
}
.svd-survey-placeholder-root .svd-survey-placeholder .svd-survey-gallery a img {
  margin-top: -5px;
  margin-right: 1em;
}
.svd-survey-placeholder-root .svd-survey-placeholder .svd-empty-message-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.svd-survey-placeholder-root .svd-survey-placeholder .svd-empty-message-container .svd-empty-message {
  margin-bottom: 1em;
  text-align: center;
  font-size: 22px;
  color: #1ab394;
  color: var(--primary-color, #1ab394);
  pointer-events: none;
  max-width: 300px;
}
.svd-survey-placeholder-root .svd-survey-placeholder .svd-empty-message-container .svd-empty-image {
  pointer-events: none;
  display: block;
}
.svd-survey-placeholder-root .svd-survey-placeholder .svd-empty-message-container .svd-empty-action {
  margin-top: 30px;
  cursor: pointer;
}

.svd_survey_designer .svd-survey-placeholder-root {
  background-color: #ffffff;
  background-color: var(--primary-bg-color, #ffffff);
}

.svd-property-editor-survey .svd-survey-nopadding {
  min-width: 7em;
}

.svd_container.sv_bootstrap_css .svd-tabs {
  line-height: 2.5em;
}

.svd_container.sv_bootstrapmaterial_css .panel.card {
  box-shadow: none;
}
.svd_container.sv_bootstrapmaterial_css .form-group {
  margin-top: 0;
}
.svd_container.sv_bootstrapmaterial_css .form-group .form-control {
  background-image: linear-gradient(0deg, var(--primary-color, #1ab394) 2px, rgba(213, 0, 0, 0) 0), linear-gradient(0deg, rgba(0, 0, 0, 0.26) 1px, transparent 0);
}
.svd_container.sv_bootstrapmaterial_css .form-group {
  margin-top: 0;
}
.svd_container.sv_bootstrapmaterial_css .form-group.is-focused label {
  color: var(--primary-color, #1ab394);
}
.svd_container.sv_bootstrapmaterial_css .svd_custom_select .form-group,
.svd_container.sv_bootstrapmaterial_css .svd_properties_grid .form-group {
  margin-top: 0;
  padding-bottom: 0;
}
.svd_container.sv_bootstrapmaterial_css .svd_custom_select .form-group .form-control,
.svd_container.sv_bootstrapmaterial_css .svd_properties_grid .form-group .form-control {
  margin-top: -3px;
  margin-bottom: 0;
}
.svd_container.sv_bootstrapmaterial_css .svd_properties_grid .glyphicon-edit {
  border-radius: 0;
  background-color: var(--primary-color, #1ab394);
  color: white;
  text-transform: uppercase;
  padding: 5px;
  font-size: 12px;
}
.svd_container.sv_bootstrapmaterial_css .svd_properties_grid .glyphicon-edit:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.svd_container.sv_bootstrapmaterial_css .svda-adorner-root span {
  position: static;
  display: inline-block;
  transition: none;
}
.svd_container.sv_bootstrapmaterial_css .modal-footer .btn + .btn {
  margin-bottom: 10px;
}
.svd_container.sv_bootstrapmaterial_css .svd-propertyeditor-condition .btn-primary {
  margin-right: 0.5em;
}
.svd_container.sv_bootstrapmaterial_css .svd-propertyeditor-condition .svd_custom_select {
  max-width: 165px;
}
.svd_container.sv_bootstrapmaterial_css .svd-propertyeditor-condition textarea {
  color: var(--primary-text-color, #676a6c);
}
.svd_container.sv_bootstrapmaterial_css .svd-logic-button {
  margin-right: 0.5em;
}
.svd_container.sv_bootstrapmaterial_css select.svda_current_type {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}
.svd_container.sv_bootstrapmaterial_css .svd_question {
  border: 1px solid var(--primary-border-color, #e7eaec);
}
.svd_container.sv_bootstrapmaterial_css .modal-content .form-group .sjs-cb-wrapper {
  margin-bottom: 6px;
}
.svd_container.sv_bootstrapmaterial_css .modal-content .form-group .sjs-cb-wrapper:last-child {
  margin-bottom: 0;
}
.svd_container.sv_bootstrapmaterial_css .modal-content .btn-danger {
  background-color: #f44336;
  color: var(--secondary-bg-color, #f4f4f4);
}
.svd_container.sv_bootstrapmaterial_css .modal-content .btn-danger:hover {
  opacity: 0.7;
}
@media (min-width: 768px) {
  .svd_container.sv_bootstrapmaterial_css .modal-content .form-inline .checkbox input[type=checkbox],
.svd_container.sv_bootstrapmaterial_css .modal-content .form-inline .radio input[type=radio] {
    position: absolute;
    margin-left: 0;
  }
  .svd_container.sv_bootstrapmaterial_css .modal-content .form-inline .checkbox span,
.svd_container.sv_bootstrapmaterial_css .modal-content .form-inline .radio span {
    top: 4px;
  }
  .svd_container.sv_bootstrapmaterial_css .modal-content .form-inline .checkbox label,
.svd_container.sv_bootstrapmaterial_css .modal-content .form-inline .radio label {
    padding-left: 45px;
    margin-bottom: 10px;
  }
}
.svd_container.sv_bootstrapmaterial_css .sv_select_wrapper .form-control {
  line-height: 3em;
  height: 3em;
}
.svd_container.sv_bootstrapmaterial_css survey-widget .sv_bootstrapmaterial_css .svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_qstn select {
  line-height: 3em;
  height: 3em;
}
.svd_container.sv_bootstrapmaterial_css .btn-primary {
  color: var(--inverted-text-color, #ffffff);
  background-color: var(--secondary-color, #1ab394);
  border-color: var(--secondary-color, #1ab394);
}
.svd_container.sv_bootstrapmaterial_css .btn-primary:active:focus {
  color: var(--primary-color, #1ab394);
}
.svd_container.sv_bootstrapmaterial_css .btn-secondary {
  background-color: var(--primary-border-color, #e7eaec);
}
.svd_container.sv_bootstrapmaterial_css .btn-secondary:hover {
  opacity: 0.8;
}
.svd_container.sv_bootstrapmaterial_css .btn-link {
  color: var(--primary-text-color, #676a6c);
  background-color: var(--secondary-bg-color, #f4f4f4);
  border-color: var(--secondary-bg-color, #f4f4f4);
}
.svd_container.sv_bootstrapmaterial_css .checkbox label,
.svd_container.sv_bootstrapmaterial_css .radio label,
.svd_container.sv_bootstrapmaterial_css label {
  color: var(--primary-text-color, #676a6c);
}
.svd_container.sv_bootstrapmaterial_css .editor-tabs > li a:hover,
.svd_container.sv_bootstrapmaterial_css .editor-tabs > li a:focus {
  background-color: var(--secondary-bg-color, #f4f4f4) !important;
  border-bottom: 1px solid var(--secondary-bg-color, #f4f4f4) !important;
}
.svd_container.sv_bootstrapmaterial_css .editor-tabs > li.active a {
  color: var(--primary-color, #1ab394) !important;
  background-color: var(--secondary-bg-color, #f4f4f4) !important;
  border: 1px solid var(--primary-border-color, #e7eaec) !important;
  border-bottom-color: var(--secondary-bg-color, #f4f4f4) !important;
}
.svd_container.sv_bootstrapmaterial_css .nav-tabs a {
  color: var(--primary-text-color, #676a6c) !important;
}
.svd_container.sv_bootstrapmaterial_css .nav-tabs > li.active > a {
  color: var(--primary-color, #1ab394) !important;
}
.svd_container.sv_bootstrapmaterial_css .nav-item.active .nav-link {
  background-color: var(--primary-bg-color, #ffffff) !important;
}
.svd_container.sv_bootstrapmaterial_css .modal-tabs {
  background: transparent;
  padding-bottom: 10px;
}
.svd_container.sv_bootstrapmaterial_css .modal-tabs > li > a {
  color: var(--primary-text-color, #676a6c) !important;
  border: 1px solid transparent !important;
}
.svd_container.sv_bootstrapmaterial_css .modal-tabs > li > a:hover {
  color: var(--primary-color, #1ab394) !important;
  border-bottom: 1px solid var(--inverted-text-color, #ffffff) !important;
}
.svd_container.sv_bootstrapmaterial_css .modal-tabs > li > a:focus {
  color: var(--primary-color, #1ab394) !important;
  border-bottom: 1px solid var(--inverted-text-color, #ffffff) !important;
}
.svd_container.sv_bootstrapmaterial_css .modal-tabs > li.active > a {
  color: var(--primary-color, #1ab394) !important;
  border: 1px solid var(--primary-border-color, #e7eaec) !important;
  border-bottom-color: var(--inverted-text-color, #ffffff) !important;
}

.svd-vertical-container {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
}

.svd-vertical-container__row {
  width: 100%;
  height: 100%;
  display: table-row;
}
.svd-vertical-container__row:first-child {
  height: 0;
}

.svd-vertical-container__cell {
  width: 100%;
  display: table-cell;
}

.svd-vertical-container__cell-content-holder {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.svd-vertical-container__cell-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.svd-vertical-container__cell-content--scrollable {
  overflow: auto;
}

.svd_editors > .svd-vertical-container {
  table-layout: fixed;
}

.svd_container {
  color: #676a6c;
  color: var(--primary-text-color, #676a6c);
}
.svd_container a {
  color: #1ab394;
  color: var(--primary-color, #1ab394);
}
.svd_container a:hover {
  color: #18a689;
  color: var(--primary-hover-color, #18a689);
}
.svd_container .btn-primary {
  color: #ffffff;
  color: var(--inverted-text-color, #ffffff);
  background-color: #1ab394;
  background-color: var(--primary-color, #1ab394);
  border-color: #1ab394;
  border-color: var(--primary-color, #1ab394);
}
.svd_container .btn-link {
  color: #676a6c;
  color: var(--primary-text-color, #676a6c);
  background-color: #f4f4f4;
  background-color: var(--secondary-bg-color, #f4f4f4);
  border-color: #f4f4f4;
  border-color: var(--secondary-bg-color, #f4f4f4);
}
.svd_container .sjs-cb-container:hover input ~ .checkmark {
  background-color: #ccc;
  background-color: var(--dd-menu-border, #ccc);
}
.svd_container .sjs-cb-container:hover input:checked ~ .checkmark {
  background-color: #18a689;
  background-color: var(--primary-hover-color, #18a689);
}
.svd_container .svd_selected_page {
  border-bottom: 1px solid #ffffff;
  border-bottom-color: var(--primary-bg-color, #ffffff);
}
.svd_container .svd_toolbar li.active a {
  background-color: #1ab394;
  background-color: var(--primary-color, #1ab394);
  color: #ffffff;
  color: var(--primary-bg-color, #ffffff);
}
.svd_container .svd_toolbar li a {
  color: #262626;
  color: var(--dd-menu-link-color, #262626);
}
.svd_container .svd_custom_select:before {
  background-color: #1ab394;
  background-color: var(--primary-color, #1ab394);
}
.svd_container .form-control:focus {
  border-color: #1ab394;
  border-color: var(--primary-color, #1ab394);
}
.svd_container a:focus,
.svd_container div:focus,
.svd_container span:focus,
.svd_container select:focus,
.svd_container tr:focus,
.svd_container input[type=checkbox]:focus + span.checkmark {
  outline-color: #1ab394;
  outline-color: var(--primary-color, #1ab394);
  outline-style: dotted;
  outline-width: 0.5px;
  outline-offset: -1px;
}
.svd_container .svd-no-outline:focus {
  outline: none;
}
.svd_container .svd_toolbar button:focus {
  outline-color: #676a6c;
  outline-color: var(--primary-text-color, #676a6c);
}
.svd_container .svd-main-color {
  color: #1ab394;
  color: var(--primary-color, #1ab394);
}
.svd_container .svd-main-background-color {
  background-color: #1ab394;
  background-color: var(--primary-color, #1ab394);
}
.svd_container .svd-main-border-color {
  border-color: #1ab394;
  border-color: var(--selection-border-color, #1ab394);
}
.svd_container .svd-light-text-color {
  color: #a7a7a7;
  color: var(--secondary-text-color, #a7a7a7);
}
.svd_container .svd-light-background-color {
  background-color: #e7eaec;
  background-color: var(--primary-border-color, #e7eaec);
}
.svd_container .svd-light-border-color {
  border-color: #e7eaec;
  border-color: var(--primary-border-color, #e7eaec);
}
.svd_container .svd-dark-border-color {
  border-color: #ddd;
  border-color: var(--secondary-border-color, #ddd);
}
.svd_container .svd-light-bg-color {
  background-color: #ffffff;
  background-color: var(--primary-bg-color, #ffffff);
}
.svd_container .svd-dark-bg-color {
  background-color: #f4f4f4;
  background-color: var(--secondary-bg-color, #f4f4f4);
}
.svd_container .svd-empty-panel {
  line-height: 20px;
  padding: 20px;
}

.svd_flowpanel[contenteditable=true]:empty:before {
  content: attr(placeholder);
}

.svd_flowpanel[contenteditable=true] {
  padding: 5px;
  display: block; /* For Firefox */
}

.svd_container {
  position: relative;
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 12px;
  width: 100%;
  height: 100%;
  outline: none;
}
.svd_container:focus {
  outline: none;
}
.svd_container .svd_questions_editor .svd_question {
  overflow: visible;
  border: 1px solid transparent;
  pointer-events: auto;
}
.svd_container .svd_questions_editor .svd_question:hover {
  border-color: #1ab394;
  border-color: var(--selection-border-color, #1ab394);
}
.svd_container .svd_surveyjs_designer_container.svd_surveyjs_designer_container .sv_main .sv_custom_header,
.svd_container #surveyjsExample .sv_main .sv_custom_header {
  background-color: transparent;
}
.svd_container .svd-textitems-column {
  width: 100%;
  display: inline-block;
  line-height: 28px;
}
.svd_container .svd-textitems-column .btn-danger {
  min-width: 2em;
  padding: 0.78em 1em;
}
.svd_container .svd-textitems-column .svd-textitems-isrequired {
  vertical-align: top;
  padding-top: 3px;
  display: inline-block;
}
.svd_container .svd-textitems-column .svd-textitems-edit {
  vertical-align: top;
  display: inline-block;
  margin-left: 1.7em;
}
.svd_container .svd-items-control-footer {
  margin-top: 1em;
}
.svd_container .svd-drag-handle {
  font-size: 20px;
  cursor: move;
}
.svd_container .svd-itemvalue-actions-container {
  white-space: nowrap;
}
.svd_container .svd-itemvalue-action {
  display: inline-block;
  vertical-align: baseline;
}
.svd_container .svd-itemvalue-action {
  display: inline-block;
  vertical-align: baseline;
}
.svd_container .form-control {
  border-radius: 2px;
}
.svd_container .form-control:focus {
  outline: 0;
  box-shadow: none;
}
.svd_container .select2-container {
  vertical-align: top;
}
.svd_container .select2-container .select2-selection--single,
.svd_container .select2-container--default.select2-container--open .select2-selection--single {
  border-radius: 0;
  border: 1px solid #ccc;
  border-color: var(--dd-menu-border, #ccc);
  height: 34px;
}
.svd_container .select2-container .select2-selection--single .select2-selection__arrow,
.svd_container .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: #1ab394;
  background: var(--primary-color, #1ab394);
  height: 34px;
  top: 0px;
  right: 0px;
  width: 34px;
}
.svd_container .select2-container .select2-selection--single .select2-selection__arrow b,
.svd_container .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: #fff transparent #fff transparent;
}
.svd_container * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.svd_container a,
.svd_container a.nav-link,
.svd_container span.nav-link {
  cursor: pointer;
}
.svd_container:not(.sv_modern_css) .btn {
  border-radius: 2px;
  padding: 0.5em 1em;
  font-size: 1em;
  line-height: 1em;
  min-width: 6em;
  font-weight: bold;
}
.svd_container .svd_content {
  padding: 30px;
  margin: 0;
  border: none;
  height: 100%;
}
.svd_container .svd_content > * {
  height: 100%;
}
.svd_container .svd_content .svd_survey_designer {
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
}
.svd_container .svd_content .svd_survey_designer .svd_editors {
  display: table-cell;
  vertical-align: top;
  padding: 0 15px;
  width: 100%;
}
.svd_container .svd_content .svd_survey_designer .svd_editors .svd_questions_editor {
  position: relative;
  padding-top: 18px;
  padding-bottom: 18px;
  min-height: 100%;
}
.svd_container .svd_content .svd_survey_designer .svd_editors .svd_questions_editor .panel-body .svd_question,
.svd_container .svd_content .svd_survey_designer .svd_editors .svd_questions_editor .card-block .svd_question {
  box-shadow: none;
  border-radius: 0;
  padding: 20px 9px;
  margin-bottom: 38px;
}
.svd_container .svd_content .svd_survey_designer .svd_editors .svd_questions_editor .panel-body .svd_question f-panel .svd_question,
.svd_container .svd_content .svd_survey_designer .svd_editors .svd_questions_editor .card-block .svd_question f-panel .svd_question {
  vertical-align: baseline;
  padding: 0;
  margin-bottom: 0;
}

.svd_container [draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
.svd_container survey-widget .form-control {
  width: 100%;
}
.svd_container .svd_wrap_elements {
  white-space: normal;
}
.svd_container .svd-matrixdropdowncolumns-table th {
  white-space: nowrap;
}
.svd_container .svd_table-nowrap {
  table-layout: fixed;
}
.svd_container .svd_table-nowrap td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.svd_container .svd_q_selected {
  border: 1px solid transparent;
}
.svd_container .svd_q_design {
  pointer-events: none;
}
.svd_container .svd_q_design_border {
  position: relative;
}
.svd_container .svd_navbarbuttons {
  margin-top: 3px;
}

.svd-manage-mask {
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
}

.svd_container .animated {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.svd_container .fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.svd_container .fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
.svd_container .modal {
  display: none;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
.svd_container .modal .modal-dialog {
  max-width: 600px;
}
.svd_container .modal .modal-body {
  max-height: 75vh;
  overflow: auto;
}
.svd_container .modal .modal-body survey-widget {
  display: block;
  margin-bottom: 1em;
}
.svd_container .modal .modal-body survey-widget .sv_main .sv_custom_header {
  background: transparent;
}
.svd_container .modal .modal-body survey-widget .svd-survey-nopadding {
  padding: 0;
  margin: 0;
}
.svd_container .svd_items_table {
  width: 100%;
}
.svd_container .svd_items_table tr th,
.svd_container .svd_items_table tr td {
  padding: 0.25em;
}
.svd_container .svd_items_table tr th .btn,
.svd_container .svd_items_table tr td .btn {
  min-width: auto;
}

.svd_container .svd_commercial_container {
  padding: 1em 0 0.5em 2em;
}
.svd_container .svd_commercial_container .svd_commercial_text {
  font-weight: bold;
  text-decoration: none;
}
.svd_container .svd_commercial_container .svd_commercial_text .svd_commercial_product {
  padding-left: 0.5em;
}

.svd_container.sv_default_css a {
  text-decoration: unset;
}
.svd_container.sv_default_css .btn,
.svd_container.sv_default_css .btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
}
.svd_container.sv_default_css .btn span,
.svd_container.sv_default_css .btn:active span {
  display: inline-block;
  padding: 1px;
}
.svd_container.sv_default_css .btn[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
}
.svd_container.sv_default_css :after,
.svd_container.sv_default_css :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.svd_container.sv_default_css .sv_progress-buttons__list li:before {
  box-sizing: content-box;
}
.svd_container.sv_default_css label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  vertical-align: top;
}
.svd_container.sv_default_css .form-group {
  margin-bottom: 15px;
}
.svd_container.sv_default_css select,
.svd_container.sv_default_css textarea {
  line-height: inherit;
}
.svd_container.sv_default_css .form-control {
  padding: 6px 12px;
  line-height: 1.5em;
  border: 1px solid #ccc;
  border-color: var(--dd-menu-border, #ccc);
  display: block;
  width: 100%;
  height: 34px;
}
.svd_container.sv_default_css .form-control.svd_custom_select {
  padding: 0;
}
.svd_container.sv_default_css .form-control.svd_custom_select select {
  height: 34px;
}
.svd_container.sv_default_css .input-group {
  position: relative;
  display: table;
  border-spacing: 0;
}
.svd_container.sv_default_css .input-group .form-control,
.svd_container.sv_default_css .input-group .input-group-addon,
.svd_container.sv_default_css .input-group .input-group-btn {
  display: table-cell;
}
.svd_container.sv_default_css .input-group .form-control {
  float: left;
}
.svd_container.sv_default_css .input-group .input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  background-color: #e7eaec;
  background-color: var(--primary-border-color, #e7eaec);
  border: 1px solid #ddd;
  border-color: var(--secondary-border-color, #ddd);
  border-radius: 2px;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}
.svd_container.sv_default_css .input-group .input-group-addon:first-child {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.svd_container.sv_default_css .input-group .input-group-addon:last-child {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.svd_container.sv_default_css .input-group .input-group-addon.btn-danger {
  background-color: #ed5565;
  background-color: var(--error-color, #ed5565);
}
.svd_container.sv_default_css .form-inline .input-group {
  display: inline-table;
  vertical-align: middle;
}
.svd_container.sv_default_css .form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}
.svd_container.sv_default_css .nav-tabs {
  margin-bottom: 0;
  padding: 0;
  line-height: 2.5em;
}
.svd_container.sv_default_css .nav-item {
  display: inline-block;
  margin-left: -3px;
}
.svd_container.sv_default_css .nav-item:first-child {
  margin-left: 0;
}
.svd_container.sv_default_css .nav-link {
  border-radius: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 0.5em 1em;
  text-align: center;
}
.svd_container.sv_default_css .nav-link.active {
  border: 1px solid #ddd;
  border-color: var(--secondary-border-color, #ddd);
  border-bottom-color: transparent;
}
.svd_container.sv_default_css .svd_editors {
  display: table-cell;
  vertical-align: top;
  padding: 0 15px;
}
.svd_container.sv_default_css .svd_properties table {
  width: 100%;
}
.svd_container.sv_default_css .svd_surveyjs_designer_container.svd_surveyjs_designer_container .sv_row .svd_question {
  margin-top: 30px;
  overflow: visible;
}
.svd_container.sv_default_css .svd_surveyjs_designer_container.svd_surveyjs_designer_container .sv_row .svd_question question-actions .svda_question_action {
  display: inline-block;
  font-size: 12px;
}
.svd_container.sv_default_css .svd_surveyjs_designer_container.svd_surveyjs_designer_container .sv_row .svd_question question-actions .svda_question_action .icon-actiondragelement {
  bottom: 0;
}
.svd_container.sv_default_css .svd_surveyjs_designer_container.svd_surveyjs_designer_container .sv_row .svd_question .svda-select-items-title {
  top: -1.8em;
  right: 0;
  padding-right: 2.5em;
}
.svd_container.sv_default_css .modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.svd_container.sv_default_css .modal .modal-dialog {
  position: relative;
  max-width: 600px;
  margin: 30px auto;
}
.svd_container.sv_default_css .modal .modal-dialog .modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
.svd_container.sv_default_css .modal .modal-dialog .modal-content .modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.svd_container.sv_default_css .modal .modal-dialog .modal-content .modal-header h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
.svd_container.sv_default_css .modal .modal-dialog .modal-content .modal-header button.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}
.svd_container.sv_default_css .modal .modal-dialog .modal-content .modal-body {
  padding: 15px;
}
.svd_container.sv_default_css .modal .modal-dialog .modal-content .modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.svd_container_disabled .item_editable {
  pointer-events: none;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
.svd_container use {
    /* IE11 - https://surveyjs.answerdesk.io/ticket/details/T1387 */
    pointer-events: none;
  }
}
.sv_select_wrapper:before {
  bottom: 2px;
}

.svd_surveyjs_designer_container .sv_select_wrapper:before {
  height: 2em;
}

.svd_surveyjs_designer_container fieldset.sv_qcbc, .svd_surveyjs_designer_container fieldset.sv_qcbx {
  min-width: 0;
}

.svd_container.sv_modern_css {
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}
.svd_container.sv_modern_css .sv-btn {
  line-height: 34px;
  height: 34px;
  border-radius: 17px;
  font-size: 14px;
  padding: 0 1.5em;
}
.svd_container.sv_modern_css .sv-btn > * {
  line-height: 34px;
}
.svd_container.sv_modern_css .sv-btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.svd_container.sv_modern_css .svd-tabs {
  padding: 0;
  margin: 0;
}
.svd_container.sv_modern_css .svd-tab {
  list-style: none;
  display: inline-block;
  margin: 0;
}
.svd_container.sv_modern_css .svd-tab:not(:first-child) {
  margin-left: -4px;
}
.svd_container.sv_modern_css .svd-tab-text {
  display: inline-block;
  padding: 1em 2em;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}
.svd_container.sv_modern_css .svd-designer-tabbed-container__tab-header {
  font-family: Raleway;
  font-size: 18px;
  font-weight: bold;
  text-transform: unset;
}
.svd_container.sv_modern_css .svd-designer-tabbed-container__tab-content {
  overflow: unset;
}
.svd_container.sv_modern_css .svd-accordion-tab-header {
  font-family: Raleway;
  font-size: 14px;
  font-weight: bold;
  min-height: 47px;
  line-height: 47px;
}
.svd_container.sv_modern_css .svd_toolbox_item_text {
  font-size: 16px;
}
.svd_container.sv_modern_css svd-property-editor {
  display: block;
  margin-bottom: 1em;
}
.svd_container.sv_modern_css svd-property-editor .sv-body__page {
  margin: 0;
}
.svd_container.sv_modern_css svd-property-editor .sv-title {
  font-size: 14px;
}
.svd_container.sv_modern_css .svd-control {
  height: 34px;
  border-radius: 2px;
  border: solid 1px #d0d0d0;
  border-color: var(--light-control-color, #d0d0d0);
  background-color: #ffffff;
  background-color: var(--primary-bg-color, #ffffff);
  padding: 0 12px;
}
.svd_container.sv_modern_css .svd-control-label {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 6px;
}
.svd_container.sv_modern_css .sjs-cb-container .checkmark:after {
  left: 6px;
  top: 2px;
}
.svd_container.sv_modern_css .svd_custom_select:before {
  background-color: transparent;
  background-image: url("./images/Arrow_down_10x10.svg");
  padding: 5px;
  margin: 12px;
}
.svd_container.sv_modern_css svd-pages-editor .svd-page {
  font-family: Raleway;
  font-size: 14px;
  font-weight: bold;
  border: none;
  color: solid 1px #a7a7a7;
  color: var(--secondary-text-color, #a7a7a7);
}
.svd_container.sv_modern_css svd-pages-editor .svd-page:hover, .svd_container.sv_modern_css svd-pages-editor .svd-page.svd_selected_page, .svd_container.sv_modern_css svd-pages-editor .svd-page.svd-page-add {
  color: #676a6c;
  color: var(--primary-text-color, #676a6c);
}
.svd_container.sv_modern_css svd-pages-editor .svd-page.svd_selected_page {
  background-color: transparent;
}
.svd_container.sv_modern_css svd-pages-editor .svd-page-selector {
  float: right;
  margin-top: 8px;
}
.svd_container.sv_modern_css svd-property-grid .svd-vertical-container__cell-content--scrollable {
  overflow-y: scroll;
}
.svd_container.sv_modern_css svd-property-grid .checkmark {
  top: 5px;
}
.svd_container.sv_modern_css .svd_object_editor {
  padding: 0;
  margin-left: 15px;
}
.svd_container.sv_modern_css .svd_items_table svd-property-editor {
  margin-bottom: 0;
}
.svd_container.sv_modern_css .propertyeditor-validators .input-group {
  display: table;
  table-layout: fixed;
}
.svd_container.sv_modern_css .propertyeditor-validators .input-group-addon, .svd_container.sv_modern_css .propertyeditor-validators .form-control {
  display: table-cell;
}
.svd_container.sv_modern_css .propertyeditor-validators .input-group-addon.btn-danger, .svd_container.sv_modern_css .propertyeditor-validators .form-control.btn-danger {
  min-width: 34px;
  text-align: center;
}
.svd_container.sv_modern_css .svd-propertyeditor-condition-wide-table td:last-child {
  line-height: 34px;
}
.svd_container.sv_modern_css .svd-vertical-container__cell-content svd-translation-group {
  margin-top: 1em;
}

.svd_container.sv_modern_css .btn-secondary, .svd_container.sv_default_css .btn-secondary {
  color: #ffffff;
  color: var(--inverted-text-color, #ffffff);
  background-color: #1ab394;
  background-color: var(--secondary-color, #1ab394);
  border-color: #1ab394;
  border-color: var(--secondary-color, #1ab394);
}
.svd_container.sv_modern_css .btn-danger, .svd_container.sv_default_css .btn-danger {
  color: #ffffff;
  color: var(--inverted-text-color, #ffffff);
  background-color: #ed5565;
  background-color: var(--error-color, #ed5565);
  border-color: #ed5565;
  border-color: var(--error-color, #ed5565);
}

.svd-toolbox-holder {
  overflow-y: auto;
  width: 100%;
  height: 100%;
}