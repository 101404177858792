@import "../../../../theme.styles.scss";

.taskList__logic {
  position: relative;

  &:hover {
    .tl__drag {
      opacity: 0.2;
    }
  }
}

.taskList {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $body;
  padding: 5px 10px;
  background: white;
  border-radius: 8px;
  margin-bottom: 5px;

  &:last-child {
    border: none;
  }

  &:hover {
    .tl__drag {
      opacity: 0.2;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .tl__task {
    padding-right: 20px;
    height: 40px;
    align-items: center;
    min-width: 50%;
    display: flex;

    p {
      margin-left: 5px;
    }
  }

  .tl__modules {
    display: flex;
    column-gap: 10px;
  }

  .tl__clickable {
    width: 100%;
    position: absolute;
    height: 40px;
    cursor: pointer;
  }

  .bl__content {
    width: 70%;
    min-width: 500px;
    max-width: 1000px;

    .retroBox {
      width: 100%;
    }
  }
}

.task__done {
  .tl__task {
    p {
      opacity: 0.2;
      text-decoration: line-through;
    }
  }

  .tl__modules {
    opacity: 0.4;
    filter: grayscale(100%);
    pointer-events: none;
  }
}

.tl__drag {
  position: absolute;
  left: -22px;
  opacity: 0.1;
  top: 13px;
  transition: 0.2s ease;
}

@media (max-width: 500px) {
  .tl__modules {
    display: none !important;
  }
}
