@import "../../../theme.styles.scss";
.jobMenu {
  overflow-x: hidden;
  width: auto;
  padding-left: 70px;
  padding-top: 20px;
  padding-right: 70px;
  padding-bottom: 10px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    .fs__false {
    }
    .sm__settings {
      svg {
        opacity: 0.8;
        &:hover {
          opacity: 0.4;
        }
      }
    }
  }
  font-family: "Poppins";
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.1)
  );
}

.sm__buttomRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  bottom: 5px;
  width: 100%;
}
.sm__favorite {
  position: absolute;
  top: 27.5px;
  left: 20px;
  color: rgba(0, 0, 0, 0.2);
}
.sm__settings {
  top: 20px;

  .bll__content {
    margin-top: 10px;

    .retroBox {
      min-height: auto;
    }
  }
  svg {
    opacity: 0.5;
    width: 20px !important;
    height: 20px !important;
    transition: 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }
}

@media (max-width: 500px) {
  .jobMenu {
    overflow-x: auto;
    overflow-y: hidden;
    padding-right: 0;
  }
  .sm__settings {
    opacity: 0.5;
    
    svg {
      opacity: 0.5;
    }
  }
}
