@import "../../theme.styles.scss";
.timeline-container {
  border: 0.3px solid grey;
  border-radius: 5px;
  overflow: hidden;
  margin-left: 5px;
  margin-right: 5px;
  * {
    border-width: 0.5px !important;
  }
  .react-calendar-timeline .rct-dateHeader-primary {
    background-color: #F7F8FA !important;
    > span {
      color: rgba(0,0,0,0.5);
    }
  }
  .react-calendar-timeline .rct-header-root {
    > :nth-child(1){
      background-color: #F7F8FA;
    }
  }
  .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    padding: 0px 0px 0px 15px;
  }

  .react-calendar-timeline  .rct-dateHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    color: rgba(0,0,0,0.5) !important;
    font-weight: 600 !important;
    font-size: 10px !important;
    border-left: 2px solid #bbb;
    border-width: 0.5px;
}
}