.message-container{
  height: 70vh;
  border-radius: 1em;
}
.container {
  height: 70vh;
  overflow-y:scroll;
}


.message__name{
    text-transform: lowercase;
}

.message__headerLeft{
    display: flex;
    align-items: center;
    font-size: 30px;
    padding-left: 10px;
    padding-top: 10px;
}

.message__headerRight{
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.message__body{
  width: 100%;
}
.message__submit{
  border-top:1px solid lightgray ;
  width: 100%;
  padding-top: 20px;
  // margin-left: -24px;
  padding-bottom: 20px;
}
