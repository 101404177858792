.message{
    
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 20px;
    // justify-content: center;
    .avatar{
        width: 4vmin !important;
        height: 4vmin !important;
        cursor: pointer ;
    }
}
.messageright{
    
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-top: 20px;
    
    .avatar{
        width: 4vmin !important;
        height: 4vmin !important;
        cursor: pointer ;
    }
    
}
.message-info >h4{
    font-size: 20px !important;
    
}

.message-info-right >p{
    font-size: 18px !important;
    display: flex;
    justify-content: flex-end;
    
}
.message-info-left >p{
    font-size: 18px !important;
    display: flex;
    justify-content: flex-start;
    
}
@media (max-width: 500px) {
    .message-info >h4{
        font-size: 12px !important;
        
    }
    .message-info >p{
        font-size: 14px !important;
        
    }
    .message{
    .avatar{
        width: 5vmax !important;
        height: 5vmax !important;
        cursor: pointer ;
    }
    }
}