@import "../../theme.styles.scss";
.colors {
  display: grid;
  grid-template-columns: repeat(3, 40px);
  grid-gap: 3px;
  width: 100%;
  .color1 {
    background: $primary;
  }
  .color2 {
    background: #e2445c;
  }
  .color3 {
    background: #40ca71;
  }
  .color4 {
    background: #a358d0;
  }
  .color5 {
    background: #2876c3;
  }
  .color6 {
    background: #1c1f3b;
  }
  .color7 {
    background: #fbcb00;
  }
  .color8 {
    background: #379afe;
  }
  .color9 {
    background: #f55f7b;
  }
  .color10 {
    background: #267e4c;
  }
  .color11 {
    background: #e1445c;
  }
  .color12 {
    background: #f65bc4;
  }
}
.colors__color {
  width: 40px;
  height: 35px;
  border-radius: 8px;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    box-shadow: inset 0px -4px 0 0 rgba(0, 0, 0, 0.3);
  }
}
