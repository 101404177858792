@import "../../../theme.styles.scss";
.retroButton {
  font-size: 12px;
  font-weight: 600;
  background: $primary;
  border-radius: 7px;
  border: none;
  color: white;
  height: 36px;
  cursor: pointer;
  box-shadow: inset 0px 0px 0 0 rgba(0, 0, 0, 0.3);
  transition: 0.2s ease all;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .children {
    transition: 0.2s ease all;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 15px;
      height: 15px;
    }
  }
  &:hover {
    box-shadow: inset 0px -2px 0 0 rgba(0, 0, 0, 0.3);
    /*    padding-top: 1px; */
    transform: scale(1.01);
    .children {
      margin-top: -2px;
    }
    .charAtFull {
      opacity: 1;
    }
  }
  &:active {
    box-shadow: inset 0px 2px 0 0 rgba(0, 0, 0, 0.3);
    /*     padding-top: 2px; */
    color: rgba(255, 255, 255, 0.5);
    transform: scale(0.99);

    .children {
      margin-top: 6px;
    }
  }
}
.small {
  height: 27px;
  font-size: 10px;
}
.gray {
  background: none;
  color: #999;
  border: none;
  box-shadow: none;
  &:active {
    transform: scale(1);
    box-shadow: none;
    .children {
      margin-top: 0px;
    }
  }
  &:hover {
    transform: scale(1);
    color: #333;
    box-shadow: none;
    .children {
      margin-top: 0px;
    }
  }
}
.create {
  width: 100%;
  background: white;
  border: 1px dotted $bodyGray;
  color: #333;
  font-weight: 100;
  padding: 27px;
}
.cancel {
  background: none;
  color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: 400;
  cursor: not-allowed;
  &:hover {
    box-shadow: none;
    transform: scale(1);
    .children {
      margin-top: 0px;
    }
  }
}
.box {
}
.charAt {
  position: relative;
  pointer-events: none;
  font-weight: 800;
  font-size: 15px;
  .charAtFull {
    position: absolute;
    background: #333;
    font-family: "Noto Sans JP", sans-serif;
    padding: 3px 10px;
    font-size: 0.7rem;
    text-transform: none;
    left: 36px;
    top: -9px;
    border-radius: 6px;
    font-weight: 800;
    opacity: 0;
  }
}

.flat {
  box-shadow: none;
  width: 100%;
  height: 50px;
  background: #eff2f5;
  color: #333;
  justify-content: flex-start;
  border-radius: 10px;
  &:hover {
    box-shadow: inset 0px -3px 0 0 rgba(0, 0, 0, 0.3);
    transform: scale(1.01);
  }
  &:active {
    box-shadow: inset 0px 3px 0 0 rgba(0, 0, 0, 0.3);
    /* padding-top: 6px; */
    color: rgba(255, 255, 255, 0.5);
    transform: scale(0.99);
  }
  svg {
    opacity: 0.8;
  }
}
.flat2 {
  box-shadow: none;
  width: 100%;
  background: #eff2f5;
  color: white;
  justify-content: flex-start;
  border-radius: 10px;
  &:hover {
    box-shadow: inset 0px -3px 0 0 rgba(0, 0, 0, 0.3);
    transform: scale(1.01);
  }
  &:active {
    box-shadow: inset 0px 3px 0 0 rgba(0, 0, 0, 0.3);
    /* padding-top: 6px; */
    color: rgba(255, 255, 255, 0.5);
    transform: scale(0.99);
  }
  svg {
    opacity: 0.8;
    margin-right: 10px;
  }
}
.danger {
  background: $danger;
  color: white;
}
.info {
  background: $info;
}
.success {
  background: $success;
}
.warning {
  background: $warning;
}
