/* $primary: #26a69a;
$secondary: #37484f;
$secondary2: #415761; */

$primary: #34b5e4;
$secondary: #e0e5ec;
$secondary2: #415761;
$success: #05ce91;
$warning: #fbcb00;
$danger: rgb(226, 68, 92);
$info: #34b5e4;
$info-light: #e9f8ff;
$button: white;
$name: #426696;

$body: linear-gradient(to right, #ececec, #e6e5e5);
//$body: url('/img/parallax/test.jpg');
$body-backgroundSize: 'cover';

$bodyGray: #c6c7c7;

$boxShadow-small: 0 5px 20px 0 rgba(128, 135, 148, 0.562);
$boxShadow-large: #ffffff73 0px 18px 50px -10px;

/* $primary: #264653;
$secondary: #37484f;
$secondary2: #415761;
$success: #009b3d;
$warning: #ff8801;
$danger: rgb(226, 68, 92);
$info: #34b5e4; */
