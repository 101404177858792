.tooltip {
  position: absolute;
  background: #666;
  color: white;
  padding: 3px 6px;
  font-size: 12px;
  top: -15px;
  border-radius: 8px;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  z-index: 50;
}

.tooltip__arrow {
  position: absolute;
  bottom: -11px;
  svg {
    color: #666;
    opacity: 1 !important;
    width: 17px !important;
    height: 17px !important;
  }
}
