@import "../../theme.styles.scss";

.list {
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  height: 83.5vh;
  padding: 10px 15px;
}

::-webkit-scrollbar-track {
  background: $body;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.list__newStatus {
}

.list__dragDelete {
  border-top: 1px solid $bodyGray;
  position: absolute;
  transition: 0.3s ease;
  padding: 10px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $bodyGray;
  opacity: 0.5;

  svg {
    color: rgba(0, 0, 0, 0.4);
    width: 25px !important;
    height: 25px !important;
  }

  &:hover {
    height: 150px;
  }
}

.list__placeholder {
  padding: 10px;
}
