.userProfile {

  .avatar {
    width: 10vmin;
    height: 10vmin;
    cursor: pointer;
  }

  .bl__content {
    width: 40%;
    max-width: 1200px;
    background-color: white;
    overflow: scroll;
  }
}

.userProfile__icon {
  z-index: 99 !important;
  width: 100%;
  height: 100%;
}

.userProfile__heading {
  margin-bottom: 30px;
}

.userProfile__info {
  display: flex;
}

.userProfile__info-avatar {
  position: relative;

  .avatar {
    width: 100px;
    height: 100px;
  }
}

.userProfile__info-user {
  display: flex;
  margin-left: 20px;

  p {
    margin-bottom: 5px;
    margin-left: 10px;
  }
}

.userProfile__info-row {
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}

.userProfile__logout {
  margin-right: 10px;

  button {
    background: linear-gradient(to right top, #9F71EB, #3CFABE);
    border-radius: 2rem;
    color: white;
    border: none;
    height: 30px;
    width: 120px;
  }

  svg {
    margin-right: 5px;
  }
}

.userProfile__buttons {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button {
    //background: linear-gradient(to right top, #9F71EB, #3CFABE);
    height: 30px;
    width: 120px;
  }
}

.userProfile__info-email {
  padding: 5px 0px;
  border: 1px solid #f6f8f9;
  border-radius: 8px;
  cursor: not-allowed;

}

.userPorfile__setImage {
  display: flex;
  align-items: center;

  .avatar {
    margin-right: 20px;
  }
}

.userProfile__info-input {
  position: absolute;
  background: blue;
  opacity: 0;
  top: 0;
  height: 100px;
  cursor: pointer;
  width: 100px;
}

@media (max-width: 500px) {
  .bl__content {
    width: 80% !important;
  }
  .userProfile__info {
      flex-direction: column;
        align-items: center;
        gap: 25px;
  }
}
