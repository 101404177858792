
.org-container {
  height: 100vh;
}

.home-container {
  width: 100%;
}

p {
  margin-bottom: 0px;
}


.surveyjsmodal.ant-modal, .surveyjsmodal > .ant-modal-content {
  height: 100vh;
  width: 100vw;
  margin: 0;
  top: 0;
}

.surveyjsmodal > .ant-modal-content > .ant-modal-body {
  height: calc(100vh);
  overflow: auto;
}