@import "../../theme.styles.scss";
.taskSettings {
  position: relative;
  .bll__content {
    margin-left: -100px;
    top: 30px !important;
  }
}

.ts__item {
  padding: 5px 10px;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $body;
  svg {
    margin-right: 5px;
    opacity: 0.7;
  }
  &:hover {
    background: $body;
  }
  &:last-child {
    border-bottom: none;
  }
}

.ts__settings {
  opacity: 1;
  transition: 0.2s ease;
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    opacity: 0.4;
  }
}

.ts__delete {
  display: flex;
  .retroBox {
    padding: 30px !important;
    width: 500px;
    background: white !important;
  }
}

.ts__delete-btns {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  .retroButton {
    height: 30px;
    &:last-child {
      width: fit-content;
    }
  }
}
.ts__changeColor {
  margin-top: 10px;
}

.ts__item-icon {
  width: 25px;
}
