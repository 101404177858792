@import "../../theme.styles.scss";
.orgFly {
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: center;
  button {
    margin-bottom: 10px;
    text-transform: uppercase;
    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    color: black;
    opacity: 0.9;
    margin-bottom: 3px;
  }
  svg {
    width: 20px !important;
    height: 20px !important;
  }
}
.sf__icon {
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  button {
    &:hover {
      transform: scale(1.05) !important;
    }
  }
}
.sf__flys {
  border-radius: 8px;
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  button {
    padding-left: 0;
    padding-right: 0;
    width: 36px;
    height: 36px;
  }
}
.sf__flysName {
  position: absolute;
  top: -9px;
  p {
    color: white;
    font-size: 10px;
    font-weight: 800;
  }
}
.sf__line {
  display: none;
  width: 100%;
  height: 2px;
  border-radius: 100%;
  background: #48575d;
  margin-bottom: 10px;
}

.sf__controls {
  position: flex;
  button {
    background: transparent;
  }
}

.sf__controls-home {
  position: relative;
  top: 10px;
}

.sf__controls-homeArrow {
  position: absolute;
  right: -26px;
  top: 9px;
  svg {
    color: white;
    width: 30px !important;
    height: 30px !important;
  }
}
