.main-screen{
    width: 100%;
    height: 75vh;
    
}


.footer{
    height: 10vh;
}
@media (max-width: 800px) {
    .main-screen {
      width: 100%;
      height: 60vh;
    }
  }