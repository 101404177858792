@import "../../theme.styles.scss";
.archivedJob{
  overflow-y: scroll;
  width: 86%;
  height: 100vh;
   background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.1)
  );
  z-index: 1;
  backdrop-filter: blur(1rem);

}

.archivedMenu{
    font-family: "Poppins";
    width: 100%;
    height: 20vh;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.1)
    );
    padding-left: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  h2{
    color: #426696;
    font-weight: bold;
  }
}

.task__logic__container{
  color: rgba(0, 0, 0, 0.2);
}
.board {
  display: flex;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  padding: 10px 15px;
}
::-webkit-scrollbar-track {
  background: $body;
}

.board__dragDelete {
  border-top: 1px solid $bodyGray;
  position: absolute;
  transition: 0.3s ease;
  padding: 10px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $bodyGray;
  opacity: 0.5;
  svg {
    color: rgba(0, 0, 0, 0.4);
    width: 25px !important;
    height: 25px !important;
  }
  &:hover {
    height: 150px;
  }
}
.board__dragDelete-droppable {
  width: 100%;
  display: flex;
  justify-content: center;
}
/* .board__dragDelete {
  position: relative;
  transition: 0.3s ease;
  padding: 10px;
  position: fixed;
  bottom: 10px;
  min-width: 45px;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(226, 68, 92);
  left: 320px;
  border-radius: 10px;
  opacity: 1;
  svg {
    color: white;
    position: absolute;
    bottom: 9px;
    left: 9px;
    width: 25px !important;
    height: 25px !important;
  }
} */
.board__placeholder {
  padding: 10px;
}

// vertical

.board__vertical {
  flex-direction: column;
}

.task__logic__container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 40px;

  > div {
    max-width: 260px;
    width: 25%;
    cursor: pointer;
    .task__logic {
      > .task {
        .task__modules {
          flex-direction: row-reverse;
        }
        .task__taskName {
          display: flex;
          width: 100%;
          padding: 0px 5px;
          justify-content: space-between;
        }
      }
      .lm__module-box {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 100%;
        margin-left: 5px;
      }
    }
  }
}
