.favoriteJob {
}
.fs__noStar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    opacity: 0.1;
    margin-bottom: 15px;
  }
  p {
    opacity: 0.4;
  }
}
.fs__item {
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
}
