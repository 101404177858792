.assignedTasks {
  height: max-content;
  padding: 10px;
  .children {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.prot {
  display: flex;
  padding: 10px;
  width: 100%;
  height: max-content;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );;
  border: solid;
  border-width: 0.1px;
  border-color: rgba(255, 255, 255, 0.7);
  border-radius: 2rem;
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.at__task {
  background: #eff2f5;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  p {
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.at__content {
  align-items: center;
  display: flex;
  width: 90%;
  //align-items: center;
  //justify-content: flex-end;

 
    margin-left: 8px;
    //display: flex;
    //justify-content: center;
  h2{
    color: #426696;
    font-weight: bold;
    margin-bottom: 0px;
  }
}

.at__modules {
  padding: 5px;
  display: flex;
  grid-column-gap: 15px;
  align-items: center;

 
    
    //display: flex;
    //justify-content: center;

}


.at__daysLeft {
  
}

.at__priority {
  pointer-events: none;
  width: 25px !important;
  height: 25px !important;
}
.at__status {
  pointer-events: none;
}
.at__noTasks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    opacity: 0.1;
    margin-bottom: 15px;
  }
  p {
    opacity: 0.4;
  }
}
.at__by {
  .avatar {
    width: 25px !important;
    height: 25px !important;
  }
}
.at__item {
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0px;
  }
}



.at__content h2{
  color: #426696;
  font-weight: bold;
}

.task-card{
 
 
  width: 250px;
  box-shadow: 10px 10px 10px -5px rgba(0,0,0,0.1) !important;
  margin-right: 30px !important;
  
  
}