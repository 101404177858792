@import "../../theme.styles.scss";

.dockHeader {
  font-family: "Poppins";
  width: 100%;
  float: right;
  //border: solid;
  //border-width: 0.5px;
  //border-color: rgba(255, 255, 255, 0.1);
  //box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  //background: linear-gradient(
  //  to right bottom,
  //  rgba(255, 255, 255, 0.3),
  //  rgba(255, 255, 255, 0.1)
  //);

  margin-bottom: 20px;
  padding-left: 80px;
  left: 0;
  position: relative;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  h3 {
    font-weight: 300;

    span {
      font-size: 25px;
    }
  }

  &:hover {
    .dh__settings-icon {
      opacity: 1;
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 1;
    opacity: 0.8;
  }
}

.dh__header {
  display: flex;
  align-items: center;
}

.deleteBtn {
  button {
    background: linear-gradient(to right top, #9F71EB, #3CFABE);
    border-radius: 2rem;
    color: white;
    border: none;
    height: 30px;
    width: 70px;
  }
}

.dh__description,
.dh__name {
  input {
    background: transparent;
    border: 1px dotted rgba(0, 0, 0, 0);
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: -10px;
    outline: none;
    width: 100%;

    &::placeholder {
      color: #333;
    }

    &:hover {
      border: 1px dotted gray;
    }

    &:focus {
      border: 1px dotted gray;

      &::placeholder {
        color: gray;
      }
    }
  }
}

.dh__name {
  input {
    font-size: 1.5em;
    font-weight: 800;
  }
}

.dh__settings {
  //position: absolute;
  //left: 60px;
  top: 20px;

  .bll__content {
    margin-top: 10px;

    .retroBox {
      min-height: auto;
    }
  }
}

.dh__btns {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;

  button {
    height: 30px;
  }
}

.dh__settings-icon {
  //cursor: pointer;
  //transition: 0.2s ease;
  //opacity: 1;

  //&:hover {
  //  transform: scale(1.1);
  //}

  svg {
    opacity: 0.5;
    width: 20px !important;
    height: 20px !important;
    transition: 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }
}

@media (max-width: 500px) {
  .dockHeader {
    padding: 20px;
  }
  .dh__settings-icon {
    opacity: 1;
  }
}
