.createOrg {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100;

  .bll__content {
    margin-left: -70px;

  }

  .cs__layer {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    backdrop-filter: blur(2.5px);
  }

  .cs__content {
    position: absolute;
    width: 550px;
  }

  .cs__icon {
    display: flex;
    width: 100%;
    margin: 10px 0;
    flex-direction: column;
    align-items: center;

    svg {
      transition: 0.2s ease all;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }

      &:active {
        transform: scale(0.95);
      }
    }

    p {
      margin-bottom: 1px;
      opacity: 0.8;
    }

    button {
      min-width: 80px;
      height: 80px;
      font-size: 40px;
      text-transform: uppercase;

      &:hover {
        .cs__edit {
          opacity: 1 !important;
        }
      }
    }
  }

  .cs__createBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;

    button {
    }
  }

  .cs__orgName {
    margin: 10px 0;

    p {
      margin-bottom: 5px;
    }
  }
}

@media (max-width: 500px) {
  .cs__content {
    width: 94% !important;
  }
}

.dashboard3 {
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 450px;
  justify-content: space-evenly;
  
  //border-radius: 2rem;
}

.create-btn {
  background: linear-gradient(
                  to right top,
                  #9F71EB,
                  #3CFABE
  );
  border: none;
  width: 100px;
  height: 40px;
  //border-radius: 2rem;
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}
