.mobile {
  position: fixed;
  left: 80vw;
  top: 2vh;
}


.mobileMenu {
  position: fixed;
  left: 0vw;
  right: 0vw;
  bottom: 0px;

  display: grid;
  grid-row-gap: 15px;
  justify-content: center;
  align-items: center;
  z-index: 200;
  button.backbtn{
    background: white;
    color: #426696;
    border: none;
    width: 25vw;
    height: 50px;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
      svg {
        width: 25px !important;
        height: 25px !important;
      }
  }
  button.homebtn{
    background: white;
    color: #426696;
    border: none;
    width: 25vw;
    height: 50px;

    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
      svg {
        width: 25px !important;
        height: 25px !important;
      }
  }
  button.surveybtn{
    background: white;
    color: #426696;
    border: none;
    width: 25vw;
    height: 50px;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
      svg {
        width: 25px !important;
        height: 25px !important;
      }
  }
  button.messagebtn{
    background: white;
    color: #426696;
    border: none;
    width: 25vw;
    height: 50px;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
      svg {
        width: 25px !important;
        height: 25px !important;
      }
  }
}


.mobilemenu__icon {
  border: 1px solid white;
  padding: 10px;
  border-radius: 10px;
  margin-right: 5px;
  background: white;
  svg {
    width: 30px !important;
    height: 30px !important;
  }
}
@media (min-width: 500px) {
  .mobileMenu {
    display: none;
  }
}
