.recentTrainings {
  .tr__item {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    button {
      // background: linear-gradient(
      //                 to right bottom,
      //                 rgba(255, 255, 255, 0.7),
      //                 rgba(255, 255, 255, 0.4)
      // );
      color: #426696;
      border: none;
      width: 100%;
      height: 40px;
      // transition: all 0.3s ease 0s;
      cursor: pointer;
      text-align: left;
      outline: none;
      font-size: 16px;
      font-family: "Poppins";
      color: #426696;
      font-weight: bold;
    }
  }
}

.trainingList {
  max-height: 165px;
  overflow-y: auto;
  overflow-x: hidden;
}

.recentTrainings__noTrainings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  p {
    text-align: center;
    margin-bottom: 10px;
  }
}

.recentTrainings__noBtn {
  display: flex;
  justify-content: flex-end;
}

.recentTrainings__create {
  button {
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
    background: white;
    color: #426696;
    border: none;
    width: 200px;
    height: 40px;
    border-radius: 1rem;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    font-family: "Poppins";
    color: #426696;
    font-weight: bold;

    svg {
      margin-right: 5px;
      color: rgba(0, 0, 0, 0.2);
    }
  }
}
