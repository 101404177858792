@import "../../theme.styles.scss";
.largeTask {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  .bl__content {
    width: 100% !important;
  }
  min-width: 500px;
  width: 100%;
  .loadModule {
    border-bottom: 1px solid #e0e5ec;
    padding: 10px 0;
    &:last-child {
      border-bottom: none;
    }
  }
  .retroBox {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px !important;
  }
}

.lt__taskStuff {
  background:rgba(255, 255, 255, 0);
  padding: 20px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  form {
    position: relative;
    width: 100%;
  }
  input {
    border: none;
    background: transparent;
    font-size: 25px;
    border: 1px dotted $body;
    padding: 5px 10px;
    margin-left: -10px;
    border-radius: 8px;
    width: 100%;
    &:hover {
      border: 1px dotted gray;
    }
    &:focus {
      border: 1px dotted gray;
    }
    &:active {
      border: 1px dotted gray;
    }
  }
  ::placeholder {
    color: #333;
    font-weight: 500;
  }
}

.lt__modules {
  padding: 20px;
}

.lt__writeUpdate {
  background: rgba(255, 255, 255, 0);
}
.lt__writeUpdate-send {
  display: flex;
  padding: 20px;
}
.lt__writeUpdate-message {
  position: relative;
  width: 100%;
  margin-left: 10px;
  border-radius: 10px;
  textarea {
    padding: 20px;
    width: 100%;
    max-width: 100% !important;
    height: 100px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 10px;
    resize: none;
    transition: 0.2s ease;
    &:focus {
      outline: none;
      height: 150px;
      border: 1px solid rgb(179, 179, 179);
    }
  }
  .lt__writeUpdate-btn {
    position: absolute;
    right: 10px;
    bottom: 20px;
    button{
      background: linear-gradient(to right top, #9F71EB, #3CFABE);
      border-radius: 2rem;
      color: white;
      border: none;
      height: 30px;
      width: 70px;
    }
  }
}
.lt__writeUpdate-msgs {
  margin: 20px 0;
}

.lt__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media (max-width: 500px) {
  .largeTask {
    min-width: 100%;
    padding: 15px;
  }

  .lt__taskStuff, .lt__modules {
    padding: 10px;
  }
  .taskDescription {
    flex-direction: column;
  }
  ._2xbD- {
    margin: 10px 0px 0px 0px;
  }
}

.survey-pdf-list {
  > span {
    font-weight: 600;
  }
  li {
    margin-left: 20px;
  }
}