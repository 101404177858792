@import "../../theme.styles.scss";

.welcomeHome {
  padding: 20px 80px;
  display: flex;
  font-size: 25px;
}


.welcomeText {
  width: 100%;
  font-size: 30px;
  font-weight: 300;
  font-family: "Poppins";
  font-weight: bold;
  justify-content: center;
  text-align: center;
}

.welcomeTextTwo {
  font-size: 25px;
  font-family: "Poppins";
  margin-top: 15px;
}

@media (max-width: 500px) {
  .welcomeHome {
    padding: 20px;
  }
}

@media (min-width: 500px) {
  .welcomeUser {
    display: none;
  }
}


.welcomeUser {
  align-items: center;
  position: relative;
  width: 80px;
  top: 15px;
}
