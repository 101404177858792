@import "../../../theme.styles.scss";

.retroLabel {
  .gray {
    background: $body;
    border: 1px solid $bodyGray;
    p {
      color: #888;
    }
  }
}
.retroLabel-box {
  background: $info-light;
  width: fit-content;
  padding: 2px 5px;
  border-radius: 8px;
  border: 1px solid #c3ecff;
  p {
    font-size: 11px;
    color: $info;
  }
}
