@import "../../../theme.styles.scss";
.boxRight {
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
}
.boxRight__content {
  box-shadow: $boxShadow-small;
  width: 40%;
  height: 100%;
  max-width: 570px;
  right: 0;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.6)
  );
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
}
.boxRight__layer {
  width: 100%;
  height: 100%;
  position: absolute;
}

@media (max-width: 500px) {
  .boxRight__content {
    width: 100%;
  }
}
