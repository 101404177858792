@import "../../../theme.styles.scss";

.personFilter {
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin-left: 10px;

  &:hover {
    .personFilter__select {
      p {
        opacity: 1;
      }
    }
  }
}

.personFilter__select,
.personFilter__false,
.personFilter__true {
  display: flex;
  align-items: center;
  width: 100px;
  position: relative;
  cursor: pointer;

  p {
    opacity: 1;
  }
}

.personFilter__pop {
  z-index: 200;
  position: relative;
  top: 0px;
  right: 100px;

  .retroBox {
    padding: 10px;
    width: 120px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: -10px;
    margin-top: 23px;
  }
}

.personFilter__member {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: $body;
  }

  .avatar {
    margin-right: 10px;
    min-width: 35px !important;
    height: 35px;
  }
}

.personFilter__true {
  .avatar {
    margin-right: 5px;
  }
}

.personFilter__remove {
  cursor: pointer;

  svg {
    opacity: 0.2;
  }
}
