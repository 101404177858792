.container{
    display: flex;
    flex-direction: row;
    
}
  .userProfile__page{
      // width: 100vh;
      // height: 100vh;
      // background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: auto;
  }
  .userProfile__form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
  }
  