@import "../../theme.styles.scss";

.filter {
  display: flex;
  align-items: center;
}

.filter__priority-fire {
  display: flex;
  margin-left: 10px;
}

.filter__text {
  position: relative;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    color: rgb(52, 181, 228);
  }
}

.filter__types {
  display: flex;
  max-height: 40px;
}

@media (max-width: 500px) {
  .filter {
    margin: 17px 0px;
  }
}
