@import "../../theme.styles.scss";

.dockJob {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  //background: linear-gradient(
  //  to right bottom,
  //  rgba(255, 255, 255, 0.4),
  //  rgba(255, 255, 255, 0.1)
  //);
  z-index: 1;
  display: flex;

  .retroBox {
    padding: 20px;
    min-height: 126px;
  }
}


//.ds__content {
//  display: flex;
//  flex-wrap: wrap;
//  row-gap: 15px;
//  padding: 30px 60px;
//}

//.ds__item {
//  min-width: 300px;
//  max-width: 400px;
//  flex: 1;
//  margin-right: 20px;
//  &:last-child {
//    margin-right: 0;
//  }
//  padding: 30px 30px;
//  border: solid;
//  border-width: 0.5px;
//  border-color: rgba(255, 255, 255, 0.7);
//  justify-content: space-evenly;
//  background: linear-gradient(
//    to right bottom,
//    rgba(255, 255, 255, 0.7),
//    rgba(255, 255, 255, 0.3)
//  );
//  border-radius: 2rem;
//}

.ds__item h2 {
  font-size: 25px;
  font-family: "Poppins";
  color: #426696;
  font-weight: bold;
}

@media (max-width: 800px) {
  .ds__content {
    padding: 20px;
  }
  .ds__item {
    min-width: 100%;
    margin-bottom: 20px;

    &:first-child {
      margin-right: 0;
    }
  }
  .task_right_grid{
    width: 800px ;
  }
}
