.favoriteStar {
}
.fs__star {
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    transform: scale(1.1);
  }
}
.fs__false {
  &:hover {
    opacity: 0.8 !important;
    color: rgb(255 227 86) !important;
  }
}
.fs__item {
  .children {
  }
  svg {
    color: #ecc500;
    width: 20px !important;
    height: 20px !important;
    margin-bottom: 3px;
    margin-right: 5px;
  }
}
