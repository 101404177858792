.boxLayerLite {
}
.bll__content {
  z-index: 150;
  position: absolute;
}
.bll__layer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
