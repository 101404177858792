.jobItem {
  // background: linear-gradient(
  //                 to right bottom,
  //                 rgba(255, 255, 255, 0.7),
  //                 rgba(255, 255, 255, 0.4)
  // );
  width: 100%;
  height: 40px;
  margin-bottom: 5px;
  grid-column-gap: 20px;
  // padding-left: 15px;
  text-align: left;
  outline: none;
  font-size: 16px;
  padding-top: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    color: #426696;
    font-size: 17px;
  }
}
