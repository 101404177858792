@import "../../../theme.styles.scss";
.miniMenu {
  .retroBox {
    &:first-of-type {
      box-shadow: $boxShadow-small !important;
      background: linear-gradient(
        to right bottom,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.6)
      );
      padding: 10px;
    }
  }
  ul {
    display: grid;
    grid-template-columns: repeat(4, 40px);
    grid-gap: 5px;
  }
  ul li {
    // background: linear-gradient(
    //     to right bottom,
    //     rgba(255, 255, 255, 0),
    //     rgba(255, 255, 255, 0)
    //   ) ;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: 0.2s ease;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      .tooltip {
        opacity: 1;
      }
      &:active {
        transform: scale(0.95);
      }
    }
    svg {
      opacity: 0.5;
    }
    .tooltip {
      position: absolute;
      top: -25px;
      // background: #666;
      color: white;
      padding: 3px 6px;
      font-size: 11px;
      border-radius: 8px;
      transition: 0.2s ease;
      opacity: 0;
      pointer-events: none;
      min-width: fit-content;
    }
  }
}
