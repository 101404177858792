* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  width: 100%;
  overflow-y: hidden;
  font-family: "Poppins";
  //background: linear-gradient(to right top, #9F71EB, #3CFABE);

}

h1 {
  color: 'var(--name)';
  font-weight: 600;
  font-size: 3rem;
  opacity: 0.8;
}

h2,
p {
  color: black;
  font-weight: 600;
}

h3 {

  font-weight: 600;
  opacity: 0.8;
}

h6 {
  font-weight: 200;
  color: white;
}


.user h1 {
  color: #426696;
  font-weight: bold;
}

.user h2 {
  color: #426696;
  font-weight: bold;
}

.glass {
  width: 100%;
  height: 100%;
  z-index: 2;
  backdrop-filter: blur(2rem);
  display: flex;
}


.dashboard {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  //background: linear-gradient(
  //                to right bottom,
  //                rgba(255, 255, 255, 0.7),
  //                rgba(255, 255, 255, 0.3)
  //);
  border-radius: 2rem;
}


.pro {
  width: 80%;
  //background: linear-gradient(to right top, #9F71EB, #3CFABE);
  border-radius: 2rem;
  color: white;
  padding: 1rem;
  position: relative;
  top: 80px;

}

.pro h2 {
  //width: 40%;
  color: white;
  font-weight: bold;
}

.signIn__content {
  flex: 2;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-evenly;
}

.signIn__noAccount {
  position: relative;
  top: -80px;
  left: 170px;
}

.signIn__noAccount h4 {
  color: black;
}

.signIn__noAccount a {
  color: darkblue;
  font-weight: bold;
}

.signIn__haveAccount {
  position: relative;
  top: -150px;
  left: 170px;
}

.signIn__haveAccount h4 {
  color: black;
}

.signIn__haveAccount a {
  color: darkblue;
  font-weight: bold;
}

// .signIn__login-content {
// width: 240px;
// min-height: 100%;
// position: relative;
// display: flex;
// justify-content: center;
//   input {
//     width: 240px;
//   }
//   h2 {
//     margin-bottom: 10px;
//     text-align: center;
//   }

@media (max-width: 500px) {
  .dashboard {
    display: none;
  }
  .header1 {
    margin-left: 10%;
  }
  .header2 {
    margin-left: 10%;
  }
  .pass {
    margin-left: -10%;
  }
  .signIn__content {
    width: 240px;
    position: relative;
    display: grid;
    justify-content: center;

    input {
      width: 340px;
    }

    button {
      width: 340px !important;
    }
  }
}
