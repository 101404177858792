@import "../../../theme.styles.scss";
.timeFilter {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}
.timeFilter-item {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  border-right: 1px solid $bodyGray;
  cursor: pointer;
  transform: 0.2s ease all;
  &:last-child {
    border-right: none;
  }
  p {
    opacity: 1;
  }
  &:hover {
    p {
      opacity: 1;
    }
  }
}
.timeFilter__active {
  background: #7C5CFF;
  p {
    color: white;
    opacity: 1;
  }
}
