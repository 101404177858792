.daysLeft {
  position: relative;
  display: flex;
  justify-content: center;

  &:hover {
    .tooltip {
      opacity: 1;
      width: 100px;
      display: flex;
      justify-content: center;
    }
  }
  svg {
    opacity: 1;
  }
}
