@import "./theme.styles.scss";
@import url('https://fonts.googleapis.com/css?family=Poppins');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  font-family: "Poppins";
  background: $body;
}
hr {
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid $body;
}
p {
  font-size: 0.8rem;
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  transform: none;
  padding: 0;
  margin: 0;
  text-transform: none;
  text-decoration: none;
  color: #333;
}
input {
  font-family: "Poppins";
}
ul li {
  list-style: none;
  text-transform: none;
  text-decoration: none;
}

textarea {
  font-family: "Poppins";
  font-size: 0.8rem;
  color: #333;
}

@media (max-width: 500px) {
  body {
    margin-bottom: 50px;
    overflow-y: auto;
  }
  input {
    font-size: 1rem;
  }
}

.survey-btn-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-popconfirm {
  z-index: 9999;
}

.MuiButton-root.MuiButton-outlined {
  background-color: #24a0ed;
  border-color: #24a0ed;
}

.ant-modal-wrap {
  z-index: 1301;
}

.MuiChip-label.MuiChip-labelMedium, .MuiButton-root.MuiButton-contained.MuiButton-containedInfo.MuiButton-sizeSmall {
  color: white !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-icon.MuiChip-iconMedium.MuiChip-iconColorPrimary {
  color: white !important;
}

