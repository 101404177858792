@import "../../theme.styles.scss";

.orgData {
  width: 100%;
  height: 100%;
}

.sd__data {
  padding: 10px 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 30px;
}

.sd__data h1 {
  font-family: "Poppins";
  color: #426696;
  font-weight: bold;
  position: relative;
  top: 8px;
}

.sd__data button {
  border: none;
  text-transform: uppercase;
  width: 8vmax;
  height: 8vmax;
  background: white;
  border-radius: 1rem;
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.sd__favorite {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  svg {
    transition: 0.2s ease all;
    color: red;
    cursor: pointer;
    opacity: 0;

    &:hover {
      transform: scale(1.2);
      color: $warning;
    }

    &:active {
      transform: scale(0.95);
    }
  }
}

.sd__favoriteActive {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  svg {
    color: $warning !important;
  }
}

.sd__createNewOrg {
  margin-top: 5px;

  button {
  }

  svg {
    color: $bodyGray;
    margin-right: 10px;
  }
}

.org-card{
 
    height: 70px;
    width: 250px;
    box-shadow: 20px 15px 15px -12px rgba(0,0,0,0.1) !important;
    margin-right: 20px !important;
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
}