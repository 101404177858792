@import "../../../theme.styles.scss";

.sn__name {
  margin-bottom: 20px;

  p {
    margin-bottom: 5px;
  }
}

.step2__next {
  display: flex;
  justify-content: flex-end;
}

.step2__statusContainer {
  display: flex;
  align-items: center;
}

.step2__statusType {
  width: fit-content;
}

.step2__types {
  background: $body;
  padding: 10px;
  border-radius: 10px;

  button {
    width: 100%;
  }
}

.step2__addStatus {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
