.home {
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
                  to right bottom,
                  rgba(255, 255, 255, 0.4),
                  rgba(255, 255, 255, 0.1)
  );
  z-index: 1;
  backdrop-filter: blur(1rem);
  display: flex;
}

::-webkit-scrollbar {
  display: none;
}


@media (max-width: 500px) {
  .org {
    display: none;
  }
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.name h3 {
  //color: #426696;
  font-weight: bold;
  position: relative;
  top: -40px;
}


.user6 {
  text-align: left;
}

.user6 h2 {
  //color: #426696;
  font-weight: bold;

}


.pro3 {
  width: 80%;
  background: linear-gradient(to right top, #9F71EB, #3CFABE);
  border-radius: 2rem;
  color: white;
  padding: 1rem;
  position: relative;
  top: 40px;

}

.pro3 h2 {
  //width: 40%;
  color: white;
  font-weight: bold;
}

/*::-webkit-scrollbar-track {
  background: black;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}*/

.home__createOrg {
  background: white;
  height: 40px;
}

.home__content {
  padding: 40px 80px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  .Card {
    margin-bottom: 30px;
  }
}

.home__recentJobs {
  button {
    background: #eff2f5;
    color: #333;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 500px) {

  .welcomeHome {
    padding: 20px;
  }

  .noTaskText {
    font-size: 30px;
    font-weight: 300;
    font-family: "Poppins";
    //color: #426696;
  }

  .home__content {
    padding: 20px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
  }
}

.dashboardt {
  margin-left: 90px;
  margin-right: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.dashboardt h1 {
  font-size: 30px;
  font-weight: 300;
  font-family: "Poppins";
  //color: #426696;
  font-weight: bold;
}

.dashboard1 h1 {
  font-size: 30px;
  font-weight: 300;
  font-family: "Poppins";
  //color: #426696;
  font-weight: bold;
}


@media (max-width: 500px) {
  .dashboardt {
    margin-left: 20px;
    margin-right: 20px;
  }
}

