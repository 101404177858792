@import "../../../theme.styles.scss";
.status {
  cursor: pointer;
  z-index: 200 !important;

  .bll__content {
    margin-left: -10px;
  }
  .retroBox {
    background: white !important;
    box-shadow: $boxShadow-small !important;
  }
}

.status__name {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.2s ease;
  margin-bottom: 2px;
  padding: 3px 5px;
  border-radius: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    margin-right: 5px;
  }

  &:hover {
    background: rgb(235, 235, 235);
  }
}

.status__show {
  position: relative;
  display: flex;
  justify-content: center;
  &:hover {
    .tooltip {
      opacity: 1;
      width: 95px;
    }
  }
}
